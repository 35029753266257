<div class="comments-container">
  <div class="comments">
    <mat-tab-group mat-stretch-tabs animationDuration="0" [(selectedIndex)]="tabIndex">
      <mat-tab label="Comments" *ngIf="showCommentsTab">
        <div class="comments-content view" *ngIf="comments.length !== 0; else noComments">
          <div class="comment" *ngFor="let comment of comments">
            <div class="initials">{{comment.COMM_USER_NAME | initials}}</div>
            <div class="comment-content">
              <div class="information">
                <span class="name">{{comment.COMM_USER_NAME | titlecase}} •</span>
                <span class="date">{{comment.COMM_INC_DATETIME | date:'dd/MM/yyyy'}}</span>
              </div>
              <p>{{comment.COMM_MESSAGE}}</p>
            </div>
          </div>
        </div>
        <ng-template #noComments>
          <div class="empty-comments">
            No comments found for this registry
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="Post Comment">
        <div class="comments-content">
          <mat-form-field class="comments-input">
            <mat-label>Comment</mat-label>
            <textarea matInput style="resize: none;" rows="5" name="comment" [(ngModel)]="commentText" (input)="onOffSideComments()"></textarea>
          </mat-form-field>
          <div class="button-container">
            <button mat-flat-button color="accent" type="submit" [disabled]="!commentText" (click)="onComment()" *ngIf="!offSideComments">Post</button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
