<h1 mat-dialog-title>Import Excel</h1>
<div mat-dialog-content class="import-container">
  <a mat-stroked-button color="accent" [href]="templateUrl" download>
    <div class="button-wrapper">
      <mat-icon>download</mat-icon>
      <span>Download Template</span>
    </div>
  </a>
  <ngx-dropzone (change)="onSelect($event)" *ngIf="files.length === 0" [accept]="types.join()">
    <ngx-dropzone-label>Drag and drop files to import</ngx-dropzone-label>
  </ngx-dropzone>
  <div class="files-view" *ngIf="files.length !== 0">
    <div class="file" *ngFor="let file of files">
      <div class="file-details">
        <mat-icon color="primary">upload_file</mat-icon>
        <p>{{file.name}}</p>
      </div>
      <button mat-icon-button (click)="onRemove(file)">
        <mat-icon color="primary">close</mat-icon>
      </button>
    </div>
  </div>
  <div class="table-container">
    <table mat-table #table [dataSource]="dataSource" matSort #sort="matSort" *ngIf="files.length !== 0">      
      <ng-container [matColumnDef]="displayedColumns[0]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CAR)" [matTooltip]="element.PB_CAR" mat-cell *matCellDef="let element">{{element.PB_CAR}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[1]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Numbers</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_INV_NUM)" [matTooltip]="element.PB_INV_NUM" mat-cell *matCellDef="let element">{{element.PB_INV_NUM}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[2]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Emission Date</th>
        <td mat-cell *matCellDef="let element">{{isDateValid(element.PB_EMIS_DT) ? (element.PB_EMIS_DT | date:'dd/MM/yyyy') : ''}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[3]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiriration Date</th>
        <td mat-cell *matCellDef="let element">{{isDateValid(element.PB_EXP_DT) ? (element.PB_EXP_DT | date:'dd/MM/yyyy') : ''}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[4]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_MBL)" [matTooltip]="element.PB_MBL" mat-cell *matCellDef="let element">{{element.PB_MBL}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[5]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_BK)" [matTooltip]="element.PB_BK" mat-cell *matCellDef="let element">{{element.PB_BK}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[6]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CTN)" [matTooltip]="element.PB_CTN" mat-cell *matCellDef="let element">{{element.PB_CTN}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[7]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{element.PB_CTN_TYPE}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[8]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CONT)" [matTooltip]="element.PB_CONT" mat-cell *matCellDef="let element">{{element.PB_CONT}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[9]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin Port</th>
        <td mat-cell *matCellDef="let element">{{element.PB_POL}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[10]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination Port</th>
        <td mat-cell *matCellDef="let element">{{element.PB_POD}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[11]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Days</th>
        <td mat-cell *matCellDef="let element">{{element.PB_TL_DAYS}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[12]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Free Days</th>
        <td mat-cell *matCellDef="let element">{{element.PB_FRT}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[13]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Days</th>
        <td mat-cell *matCellDef="let element">{{element.PB_CH_DAYS}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[14]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">{{isDateValid(element.PB_ST_DT) ? (element.PB_ST_DT | date:'dd/MM/yyyy') : ''}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[15]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let element">{{isDateValid(element.PB_END_DT) ? (element.PB_END_DT | date:'dd/MM/yyyy') : ''}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[16]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount USD</th>
        <td mat-cell *matCellDef="let element">{{element.PB_VAL_USD | currency}}</td>
      </ng-container>
      <ng-container [matColumnDef]="displayedColumns[17]">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Verification</th>
        <td mat-cell *matCellDef="let element"><span class="status-flag" [ngClass]="{valid: element.IS_VALID === 0, invalid: element.IS_VALID === 1, duplicate: element.IS_VALID === 2}"></span></td>    
      </ng-container>
      
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
  <div class="import-actions" [ngClass]="{'hidden': files.length === 0}">
    <div mat-dialog-actions>
      <button mat-stroked-button type="button" color="accent" (click)="close()">Cancel</button>
      <button mat-flat-button color="accent" [disabled]="!canImport" (click)="import()">Import</button>
    </div>
    <div class="count-invoices">
      <p>{{countInvoices}} Invoice(s)</p>
    </div>
  </div>
</div>
