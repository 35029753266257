<header>
  <h2>Payables - Generate P.O.</h2>
  <div class="actions">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>        
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="actionMenu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #actionMenu xPosition="before">
        <button mat-menu-item (click)="openDiscountDialog({element: selection.selected})">
          <mat-icon>attach_money</mat-icon>
          <span>Apply Discount</span>
        </button>
        <button mat-menu-item *ngIf="willGeneratePo(selection.selected)" (click)="openGeneratePoDialog({element: selection.selected})">
          <mat-icon>request_quote</mat-icon>
          <span>Generate P.O.</span>
        </button>
      </mat-menu>      
      <button mat-flat-button color="accent" (click)="export()">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #table [dataSource]="filteredPayableTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="PRC_SER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
        <td [matTooltipDisabled]="!isOverflow(element.PRC_SER)" [matTooltip]="element.PRC_SER" mat-cell *matCellDef="let element">{{element.PRC_SER}}</td>
      </ng-container>
      <ng-container matColumnDef="PRC_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PRC_HBL)" [matTooltip]="element.PRC_HBL" mat-cell *matCellDef="let element">{{element.PRC_HBL}}</td>
      </ng-container>
      <ng-container matColumnDef="PRC_CUS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.PRC_CUS)" [matTooltip]="element.PRC_CUS" mat-cell *matCellDef="let element">{{element.PRC_CUS}}</td>
      </ng-container>
      <ng-container matColumnDef="PRC_SHIP">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Shipment</th>
        <td [matTooltipDisabled]="!isOverflow(element.PRC_SHIP)" [matTooltip]="element.PRC_SHIP" mat-cell *matCellDef="let element">{{element.PRC_SHIP}}</td>
      </ng-container>  
    
      <ng-container matColumnDef="PB_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CAR)" [matTooltip]="element.PB_CAR" mat-cell *matCellDef="let element">{{element.PB_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_INV_NUM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_INV_NUM)" [matTooltip]="element.PB_INV_NUM" mat-cell *matCellDef="let element">{{element.PB_INV_NUM}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_MBL)" [matTooltip]="element.PB_MBL" mat-cell *matCellDef="let element">{{element.PB_MBL}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_BK">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_BK)" [matTooltip]="element.PB_BK" mat-cell *matCellDef="let element">{{element.PB_BK}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_CTN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CTN)" [matTooltip]="element.PB_CTN" mat-cell *matCellDef="let element">{{element.PB_CTN}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CTN_TYPE)" [matTooltip]="element.PB_CTN_TYPE" mat-cell *matCellDef="let element">{{element.PB_CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_CONT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CONT)" [matTooltip]="element.PB_CONT" mat-cell *matCellDef="let element">{{element.PB_CONT}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_POL)" [matTooltip]="element.PB_POL" mat-cell *matCellDef="let element">{{element.PB_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_POD)" [matTooltip]="element.PB_POD" mat-cell *matCellDef="let element">{{element.PB_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_FRT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Freetime</th>
        <td mat-cell *matCellDef="let element">{{element.PB_FRT}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_CH_DAYS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Days</th>
        <td mat-cell *matCellDef="let element">{{element.PB_CH_DAYS}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_ST_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">{{element.PB_ST_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_END_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let element">{{element.PB_END_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_VAL_USD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount USD</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_VAL_USD)" [matTooltip]="element.PB_VAL_USD" mat-cell *matCellDef="let element">{{element.PB_VAL_USD | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_DIS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount USD</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_DIS)" [matTooltip]="element.PB_DIS" mat-cell *matCellDef="let element">
          {{element.PB_DIS_TYPE === 0 ? (element.PB_DIS / 100 | percent:'1.4') : ''}}
          {{element.PB_DIS_TYPE === 1 ? (element.PB_DIS | currency) : ''}}
        </td>
      </ng-container>
      <ng-container matColumnDef="PB_VAL_DIS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total With Discount USD</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_VAL_DIS)" [matTooltip]="element.PB_VAL_DIS" mat-cell *matCellDef="let element">{{element.PB_VAL_DIS | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PB_CH_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Code</th>
        <td [matTooltipDisabled]="!isOverflow(element.PB_CH_CODE)" [matTooltip]="element.PB_CH_CODE" mat-cell *matCellDef="let element">{{element.PB_CH_CODE}}</td>
      </ng-container>
    
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">      
          <button mat-icon-button matTooltip="Apply Discount" (click)="openDiscountDialog({element})">
            <mat-icon color="primary">attach_money</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Change Charge Code" (click)="openChargeCodeDialog({element})">
            <mat-icon color="primary">change_circle</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Generate P.O." (click)="openGeneratePoDialog({element})">
            <mat-icon color="primary">request_quote</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <!-- Header row first group -->
      <ng-container matColumnDef="header-first-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="5">File Information</th>
      </ng-container>
    
      <!-- Header row fourth group -->
      <ng-container matColumnDef="header-second-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="18">Carrier Invoice</th>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="['header-first-group', 'header-second-group']"></tr>
      <tr mat-header-row *matHeaderRowDef="payableTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: payableTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>  
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>

