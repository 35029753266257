<header>
  <h2>Payables - Payment Order (SAT)</h2>
  <div class="actions">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>        
      </button>
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="openDeletePoDialog(selection.selected)">
          <mat-icon>delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export(selection.selected)">Export</button>
    </div>
  </div>
</header>

<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #table [dataSource]="filteredPaymentOrderTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
    
      <ng-container matColumnDef="PO_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DDM Payment Order</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_ID)" [matTooltip]="element.PO_ID" mat-cell *matCellDef="let element">PODDM{{element.PO_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td [matTooltipDisabled]="!isOverflow(paymentOrderType[element.PO_TYPE])" [matTooltip]="paymentOrderType[element.PO_TYPE]" mat-cell *matCellDef="let element">{{paymentOrderType[element.PO_TYPE]}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_CAR)" [matTooltip]="element.PO_CAR" mat-cell *matCellDef="let element">{{element.PO_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_BULK">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Is Bulk</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_BULK)" [matTooltip]="element.PO_BULK" mat-cell *matCellDef="let element">
          {{element.PO_BULK === '1' ? 'Yes' : 'No'}}
        </td>
      </ng-container>
      <ng-container matColumnDef="PO_VAL_USD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount USD</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_VAL_USD)" [matTooltip]="element.PO_VAL_USD" mat-cell *matCellDef="let element">{{element.PO_VAL_USD | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_VAL_DIS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total With Discount USD</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_VAL_DIS)" [matTooltip]="element.PO_VAL_DIS" mat-cell *matCellDef="let element">{{element.PO_VAL_DIS | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_CUR_RATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Currency Rate</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_CUR_RATE)" [matTooltip]="element.PO_CUR_RATE" mat-cell *matCellDef="let element">{{element.PO_CUR_RATE | currency:'R$':'symbol':'1.2-4'}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_VAL_BRL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total BRL</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_VAL_BRL)" [matTooltip]="element.PO_VAL_BRL" mat-cell *matCellDef="let element">{{element.PO_VAL_BRL | currency:'R$'}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_SAT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>SAT</th>
        <td [matTooltipDisabled]="!isOverflow(element.PO_SAT)" [matTooltip]="element.PO_SAT" mat-cell *matCellDef="let element">{{element.PO_SAT}}</td>
      </ng-container>
      <ng-container matColumnDef="PO_PAY_DT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Date</th>
        <td mat-cell *matCellDef="let element">{{element.PO_PAY_DT | date:'dd/MM/yyyy'}}</td>
      </ng-container>  
      <ng-container matColumnDef="PO_PRC_ST">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td [matTooltipDisabled]="!isOverflow(paymentOrderStatus[element.PO_PRC_ST])" [matTooltip]="paymentOrderStatus[element.PO_PRC_ST]" mat-cell *matCellDef="let element">{{paymentOrderStatus[element.PO_PRC_ST]}}</td>
      </ng-container>
      <ng-container matColumnDef="invoices">
        <th mat-header-cell *matHeaderCellDef>Invoices</th>
        <td mat-cell *matCellDef="let element">
          <button mat-stroked-button color="accent" (click)="openInvoicesViewDialog({paymentOrder: element})">View Invoices</button>      
        </td>
      </ng-container>
      
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">      
          <button mat-icon-button *ngIf="element.PO_PRC_ST === 0" matTooltip="Insert SAT" (click)="openInsertSatDialog({element})">
            <mat-icon color="primary">paid</mat-icon>
          </button>
          <button mat-icon-button *ngIf="element.PO_TYPE === 0" matTooltip="Generate Extra" (click)="openPaymentOrderExtraDialog({element})">
            <mat-icon color="primary">currency_exchange</mat-icon>
          </button>
          <button mat-icon-button (click)="openDeletePoDialog(element)">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="paymentOrderTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: paymentOrderTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>