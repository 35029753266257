<h1 mat-dialog-title>Change Status</h1>
<div mat-dialog-content>
  <div class="event-info-container" *ngIf="isValidComment()">
    <div class="event-info">
      <div class="event-header">
        <p>{{data.element.INVOICE_CMT_USERNAME | titlecase}}</p>
        <span>•</span>
        <p>{{data.element.INVOICE_CMT_INC_DT | date:'dd/MM/yyyy'}}</p>
      </div>
      <div class="event-status">
        <p>{{invoiceStatus[data.element.INVOICE_CMT_ST]}}</p>
      </div>
      <div class="event-status">
        <p>Branch: {{data.element.INVOICE_CMT_BRANCH}}</p>
        <span>-</span>
        <p>Owner: {{data.element.INVOICE_CMT_OWNER}}</p>
      </div>
      <p class="action-name">{{decodeUri(data.element.INVOICE_CMT)}}</p>
    </div>
  </div>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field class="wide">
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status" (selectionChange)="verifyStatus()">
          <mat-option value="6">Release Billing</mat-option>
          <mat-option value="5">Under Analysis</mat-option>
          <mat-option value="7">Customer Notified</mat-option>
          <mat-option value="1">In Dispute</mat-option>
          <mat-option value="2">Invoiced</mat-option>
          <mat-option value="3">Paid</mat-option>
          <mat-option value="4">Canceled</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="form-group" *ngIf="showComment()">
      <mat-form-field>
        <mat-label>Branch</mat-label>
        <mat-select name="branch" formControlName="branch">
          <mat-option value="SAO">SAO</mat-option>
          <mat-option value="SSZ">SSZ</mat-option>
          <mat-option value="MAO">MAO</mat-option>
          <mat-option value="POA">POA</mat-option>
          <mat-option value="CWB">CWB</mat-option>
          <mat-option value="RIO">RIO</mat-option>
          <mat-option value="BHZ">BHZ</mat-option>
          <mat-option value="SSA">SSA</mat-option>
          <mat-option value="NVT">NVT</mat-option>
          <mat-option value="ITJ">ITJ</mat-option>
          <mat-option value="CPQ">CPQ</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Owner</mat-label>
        <input matInput name="owner" formControlName="owner">        
      </mat-form-field>  
    </div>
    <dhl-comment [showCommentsTab]="false" [offSideComments]="true" *ngIf="showComment()" (comment)="onComment($event)"></dhl-comment>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="showComment() && (!comment || form.invalid)">Apply</button>
    </div>
  </form>
</div>
