<header>
  <h2>{{destiny | titlecase}} - Generate Invoices</h2>
  <div class="actions-process-container">
    <div class="search-process-container">
      <mat-form-field class="process-container-search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <a mat-stroked-button color="accent" [routerLink]="['/invoices']" *ngIf="!isPayable">Invoice List</a>
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu xPosition="before">
        <button mat-menu-item (click)="recalculate(selection.selected)">
          <mat-icon>refresh</mat-icon>
          <span>Recalculate container(s)</span>
        </button>
        <button mat-menu-item (click)="process(selection.selected)" *ngIf="!isPayable && canProcessContainers(selection.selected)">
          <mat-icon>currency_exchange</mat-icon>
          <span>Process container(s)</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="filteredProcessContainerTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #processContainerTable [dataSource]="filteredProcessContainerTableDataSource" multiTemplateDataRows matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_CUS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_CUS)" [matTooltip]="element.PROCESS_CUS" mat-cell *matCellDef="let element">{{element.PROCESS_CUS}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_HBL)" [matTooltip]="element.PROCESS_HBL" mat-cell *matCellDef="let element">
          <a class="dhl-link" matTooltip="Open Shipment information" [routerLink]="['/shipment/edit', element.PROCESS_SHIP]" target="_blank">
            {{element.PROCESS_HBL}}
            <sup>({{element.PROCESS_TOTAL_CTN}})</sup>
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_CTN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Number">Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_CTN)" [matTooltip]="element.PROCESS_CTN" mat-cell *matCellDef="let element">{{element.PROCESS_CTN}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container Type">Type</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_CTN_TYPE)" [matTooltip]="element.PROCESS_CTN_TYPE" mat-cell *matCellDef="let element">{{element.PROCESS_CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_CAR)" [matTooltip]="element.PROCESS_CAR" mat-cell *matCellDef="let element">{{element.PROCESS_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_MBL)" [matTooltip]="element.PROCESS_MBL" mat-cell *matCellDef="let element">
          {{element.PROCESS_MBL}}
          <sup class="mbl-dup" *ngIf="element.PROCESS_DUP_MBL > 1">({{element.PROCESS_DUP_MBL}})</sup>
        </td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_VES">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Vessel">Vessel</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_VES)" [matTooltip]="element.PROCESS_VES" mat-cell *matCellDef="let element">{{element.PROCESS_VES}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_VOY">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Voyage">Voyage</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_VOY)" [matTooltip]="element.PROCESS_VOY" mat-cell *matCellDef="let element">{{element.PROCESS_VOY}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_POL)" [matTooltip]="element.PROCESS_POL" mat-cell *matCellDef="let element">{{element.PROCESS_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_POD)" [matTooltip]="element.PROCESS_POD" mat-cell *matCellDef="let element">{{element.PROCESS_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_ATD" *ngIf="destiny === 'demurrage'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_ATD | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_ARR_DATE" *ngIf="destiny === 'demurrage'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Arrival">Arrival</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_ARR_DATE | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_GOU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Empty Return/Gate Out">{{destiny === 'demurrage' ? 'Empty Return' : 'Gate Out'}}</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_GOU | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_GIN_GOU" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Days">Days</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_GIN_GOU}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_GIN" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate In">Gate In</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_GIN | date:'dd/MM/yyyy'}}</td>
      </ng-container>      
      <ng-container matColumnDef="PROCESS_LDG_GIN" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Days">Days</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_LDG_GIN}}</td>
      </ng-container>      
      <ng-container matColumnDef="PROCESS_LDG" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_LDG | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_CUSTOM_DATE" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Custom End">End Reference</th>
        <td mat-cell *matCellDef="let element" color="accent" class="font-weight">{{element.PROCESS_CUSTOM_DATE | date:'dd/MM/yyyy'  }}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_RET_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by End Date">End Date</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_RET_DATE | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_FRT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Free Time">Free Time</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_FRT}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_DEMU_DAYS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Charge Days">Charge Days</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_DEMU_DAYS}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_PERDIEM">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Perdiem">Perdiem</th>
        <td [matTooltipDisabled]="!isOverflow(element.PROCESS_PERDIEM)" [matTooltip]="element.PROCESS_PERDIEM" mat-cell *matCellDef="let element">{{element.PROCESS_PERDIEM}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Amount">Amount USD</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_AMT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_ORIGIN_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Origin Station">Origin Station</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_ORIGIN_AMT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PF_AMT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Payables Forecast">Payables Forecast</th>
        <td mat-cell *matCellDef="let element">{{element.PF_AMT | currency}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_AGREEMENT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Agreement">DDM Agreement</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_AGREEMENT}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_INC_DATETIME">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Inclusion Date">Inclusion Date</th>
        <td mat-cell *matCellDef="let element">{{element.PROCESS_INC_DATETIME | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_EXC_DESC">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Exception">Exception</th>
        <td mat-cell *matCellDef="let element">
          <div class="exception">
            <span class="flag"
              [ngClass]="{
                'grey': !element.PROCESS_EXC_DESC,
                'green': element.PROCESS_EXC_DESC             
              }">
            </span>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="PROCESS_IS_PARTIAL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Partial">Is Partial</th>
        <td mat-cell *matCellDef="let element">
          <div class="exception">
            <span class="flag"
              [ngClass]="{
                'grey': element.PROCESS_IS_PARTIAL === '0',
                'green': element.PROCESS_IS_PARTIAL === '1'             
              }">
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="END_REFERENCE" *ngIf="destiny === 'detention'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <ng-container>
            <button mat-stroked-button color="accent" [mat-menu-trigger-for]="menuEndReference" [disabled]="element.CTN_PROCESS_STATUS == '9'">End Reference</button>

            <mat-menu #menuEndReference="matMenu" overlapTrigger>
              <button mat-menu-item (click)="openCustomDateDialog({element: element, dateType: 'custom'})">
                <mat-icon color="primary">calendar_today</mat-icon>
                <span>Custom</span>
              </button>

              <!-- <button mat-menu-item (click)="openCustomDateDialog({element: element, dateType: 'gateIn'})">
                <mat-icon color="primary">calendar_today</mat-icon>
                <span>Gate In</span>
              </button> -->

              <button mat-menu-item (click)="openCustomDateDialog({element: element, dateType: 'loading'})">
                <mat-icon color="primary">calendar_today</mat-icon>
                <span>Loading</span>
              </button>
            </mat-menu>

          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Recalculate container" color="primary" (click)="recalculate(element)">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-icon-button *ngIf="element.PROCESS_DEMU_DAYS > 0 && !isPayable" color="primary" matTooltip="Process container" 
            (click)="element.PROCESS_IS_PARTIAL === '0' ? process(element) : processPartial(element)">
            <mat-icon>currency_exchange</mat-icon>
          </button>
        </td>
      </ng-container>
      <ng-container matColumnDef="blank-line">
        <th mat-header-cell *matHeaderCellDef></th>
        <td class="separator-cell" mat-cell *matCellDef="let element" [attr.colspan]="processContainerTableDisplayedColumns.length"></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="processContainerTableDisplayedColumns"></tr>
      <tr class="separator-row" mat-row *matRowDef="let row; columns: ['blank-line']; when: showSeparatorRow"></tr>
      <tr mat-row *matRowDef="let row; columns: processContainerTableDisplayedColumns;"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
