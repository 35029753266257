<form [formGroup]="agreementForm" (submit)="onFormSubmit($event)">
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isAgreementInformationOpen, 'cloned': cloned}" (click)="isAgreementInformationOpen = !isAgreementInformationOpen">
      <span>Agreement Information</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isAgreementInformationOpen}">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Type</mat-label>
          <input matInput name="type" formControlName="type" readonly>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Description</mat-label>
          <mat-select name="description" formControlName="description" (selectionChange)="validateFields(); validateApprovedBy(); getContainerByType();">
            <mat-option value="bid">BID</mat-option>
            <mat-option value="spot">SPOT</mat-option>
            <mat-option *ngIf="agreementType === 'purchase'" value="fak">FAK</mat-option>
            <mat-option value="bulk-bid">BULK BID</mat-option>
            <mat-option value="bulk-spot">BULK SPOT</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('description').invalid">{{getErrorMessage('description')}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="agreementForm.get('description').value == 'spot' || agreementForm.get('description').value == 'bulk-spot'">
          <mat-label>HBL / MBL / Shipment</mat-label>
          <input matInput name="hblmbl" formControlName="hblmbl" (blur)="getMblData()">
          <mat-error *ngIf="agreementForm.get('hblmbl').invalid">{{getErrorMessage('hblmbl')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Service</mat-label>
          <mat-select name="service" formControlName="service" (selectionChange)="validateService(); getDefaultRules()">
            <mat-option value="demurrage">DEMURRAGE</mat-option>
            <mat-option value="detention">DETENTION</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('service').invalid">{{getErrorMessage('service')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field *ngIf="agreementForm.get('description').value == 'bid' || agreementForm.get('description').value == 'fak'  || agreementForm.get('description').value == 'bulk-bid'">
          <mat-label>Start Date</mat-label>
          <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker">
          <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #startDatePicker></mat-datepicker>
          <mat-error *ngIf="agreementForm.get('startDate').invalid">{{getErrorMessage('startDate')}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="agreementForm.get('description').value == 'bid' || agreementForm.get('description').value == 'fak'  || agreementForm.get('description').value == 'bulk-bid'">
          <mat-label>Validity Date</mat-label>
          <input matInput name="validityDate" formControlName="validityDate" [matDatepicker]="validityDatePicker" (dateChange)="checkValidityDate()">
          <mat-datepicker-toggle matSuffix [for]="validityDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #validityDatePicker></mat-datepicker>
          <mat-error *ngIf="agreementForm.get('validityDate').invalid">{{getErrorMessage('validityDate')}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="agreementForm.get('description').value == 'bid' || agreementForm.get('description').value == 'fak' || agreementForm.get('description').value == 'bulk-bid'">
          <mat-label>BID Expiration</mat-label>
          <input matInput name="startDate" formControlName="bidDate" [matDatepicker]="bidDatePicker">
          <mat-datepicker-toggle matSuffix [for]="bidDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #bidDatePicker></mat-datepicker>
          <mat-error *ngIf="agreementForm.get('bidDate').invalid">{{getErrorMessage('bidDate')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Status</mat-label>
          <mat-select name="status" formControlName="status">
            <mat-option [value]="1">ACTIVE</mat-option>
            <mat-option [value]="0">INACTIVE</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('status').invalid">{{getErrorMessage('status')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>DHL Contract</mat-label>
          <input matInput name="dhlContract" formControlName="dhlContract">
          <mat-error *ngIf="agreementForm.get('dhlContract').invalid">{{getErrorMessage('dhlContract')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Requested By</mat-label>
          <mat-chip-list #requestedList>
            <mat-chip *ngFor="let requested of requestedByList" (removed)="removeRequested(requested)">
              {{requested}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input name="requestedBy" formControlName="requestedBy"
              [matChipInputFor]="requestedList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="addOnBlur"
              (matChipInputTokenEnd)="addRequested($event)">
          </mat-chip-list>
          <mat-error *ngIf="agreementForm.get('requestedBy').invalid">{{getErrorMessage('requestedBy')}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="agreementForm.get('description').value !== 'spot' || agreementForm.get('description').value !== 'bulk-spot'">
          <mat-label>Approved By</mat-label>
          <mat-chip-list #approvedList>
            <mat-chip *ngFor="let approved of approvedByList" (removed)="removeApproved(approved)">
              {{approved}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input name="approvedBy" formControlName="approvedBy"
                    [matChipInputFor]="approvedList"
                    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                    [matChipInputAddOnBlur]="addOnBlur"
                    (matChipInputTokenEnd)="addApproved($event)">
          </mat-chip-list>
          <mat-error *ngIf="agreementForm.get('approvedBy').invalid">{{getErrorMessage('approvedBy')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="expandable" *ngIf="agreementForm.get('description').value !== 'fak' && agreementForm.get('description').value !== 'bulk'">
    <button type="button" class="open-expandable" [ngClass]="{'open': isCustomerInformationOpen, 'cloned': cloned}" (click)="isCustomerInformationOpen = !isCustomerInformationOpen">
      <span>Customer Information</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isCustomerInformationOpen}">
      <div class="form-group-button-container">
        <button type="button" mat-stroked-button color="accent" (click)="openCustomerGroupDialog({title: 'Add Customer Group', mode: 'add'})" *ngIf="!agreementForm.get('group').value">Create new Group</button>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Group</mat-label>
          <mat-select name="group" formControlName="group" [disabled]="!!agreementForm.get('customer').value" (selectionChange)="setValidators('group', ['customer'])">
            <mat-option>
              <ngx-mat-select-search formControlName="groupFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let group of filteredCustomerGroups | async" [value]="group.CUS_GRP_ID">{{group.CUS_GRP_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('group').setValue(''); setValidators('group', ['customer']);" *ngIf="agreementForm.get('group').value">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('group').invalid">{{getErrorMessage('group')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Customer</mat-label>
          <mat-select name="customer" formControlName="customer" [disabled]="!!agreementForm.get('group').value" (selectionChange)="setValidators('customer', ['group'])">
            <mat-option>
              <ngx-mat-select-search formControlName="customerFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let customer of customers" [value]="customer.CUS_ID">{{customer.CUS_CODE}} - {{customer.CUS_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('customer').setValue(''); agreementForm.get('customerFilter').setValue(''); setValidators('customer', ['group']);" *ngIf="!!agreementForm.get('customer').value">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('customer').invalid">{{getErrorMessage('customer')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group" *ngIf="agreementForm.get('service').value === 'demurrage'">
        <mat-form-field>
          <mat-label>Shipper</mat-label>
          <mat-select name="shipper" formControlName="shipper">
            <mat-option>
              <ngx-mat-select-search formControlName="shipperFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let shipper of shippers" [value]="shipper.CUS_ID">{{shipper.CUS_CODE}} - {{shipper.CUS_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('shipper').setValue(''); agreementForm.get('shipperFilter').setValue('');" *ngIf="!!agreementForm.get('shipper').value">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('shipper').invalid">{{getErrorMessage('shipper')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Notify</mat-label>
          <mat-select name="notify" formControlName="notify">
            <mat-option>
              <ngx-mat-select-search formControlName="notifyFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let notify of notifies" [value]="notify.CUS_ID">{{notify.CUS_CODE}} - {{notify.CUS_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('notify').setValue(''); agreementForm.get('notifyFilter').setValue('');" *ngIf="!!agreementForm.get('notify').value">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('notify').invalid">{{getErrorMessage('notify')}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="expandable">
    <button type="button" class="open-expandable" [ngClass]="{'open': isAgreementParametersOpen}" (click)="isAgreementParametersOpen = !isAgreementParametersOpen">
      <span>Agreement Parameters</span>
      <mat-icon>expand_more</mat-icon>
    </button>
    <div class="expandable-content" [ngClass]="{'collapsed': !isAgreementParametersOpen}">
      <div class="form-group">
        <mat-form-field>
          <mat-label>Carrier</mat-label>
          <mat-select name="carrier" formControlName="carrier" (selectionChange)="getDefaultRules()">
            <mat-option>
              <ngx-mat-select-search formControlName="carrierFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option [value]="0">ALL CARRIERS</mat-option>
            <mat-option *ngFor="let carrier of filteredCarriers | async" [value]="carrier.DIM_SK_CRR">{{carrier.RELATIPF_APA3CD}} - {{carrier.RELATIPF_APCPTK}}</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('carrier').invalid">{{getErrorMessage('carrier')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>FAK / NAC</mat-label>
          <mat-select name="faknac" formControlName="faknac">
            <mat-option value="FAK">FAK</mat-option>
            <mat-option value="NAC">NAC</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('faknac').invalid">{{getErrorMessage('faknac')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Contract</mat-label>
          <input matInput name="contract" formControlName="contract">
          <mat-error *ngIf="agreementForm.get('contract').invalid">{{getErrorMessage('contract')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Container Type</mat-label>
          <mat-select name="containerType" formControlName="containerType" multiple (selectionChange)="getDefaultRules()">
            <mat-option>
              <ngx-mat-select-search formControlName="containerTypeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let type of filteredContainerTypes | async" [value]="type.DIM_SK_CTN_TYP">{{type.CONTYPPF_QAMPZ}}</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('containerType').invalid">{{getErrorMessage('containerType')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Start Reference</mat-label>
          <input matInput name="startReference" formControlName="startReference" readonly="true" />
          <mat-error *ngIf="agreementForm.get('startReference').invalid">{{getErrorMessage('startReference')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>End Reference</mat-label>
          <input matInput name="endReference" formControlName="endReference" readonly="true" />
          <mat-error *ngIf="agreementForm.get('endReference').invalid">{{getErrorMessage('endReference')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Origin Trade</mat-label>
          <mat-select name="originTrade" formControlName="originTrade" multiple [disabled]="isFieldDisabled('originCountry', 'pol')" (selectionChange)="setValidators('originTrade', ['originCountry', 'pol'])">
            <mat-option>
              <ngx-mat-select-search formControlName="originTradeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('originTrade').setValue(''); agreementForm.get('originTradeFilter').setValue('');" *ngIf="!!agreementForm.get('originTrade').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('originTrade').invalid">{{getErrorMessage('originTrade')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Origin Country</mat-label>
          <mat-select name="originCountry" formControlName="originCountry" multiple [disabled]="isFieldDisabled('originTrade', 'pol')" (selectionChange)="setValidators('originCountry', ['originTrade', 'pol'])" (opened)="filterService()">
            <mat-option>
              <ngx-mat-select-search formControlName="originCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredOriginCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('originCountry').setValue(''); agreementForm.get('originCountryFilter').setValue('');" *ngIf="!!agreementForm.get('originCountry').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('originCountry').invalid">{{getErrorMessage('originCountry')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>POL</mat-label>
          <mat-select name="pol" formControlName="pol" multiple [disabled]="isFieldDisabled('originTrade', 'originCountry')" (selectionChange)="setValidators('pol', ['originTrade', 'originCountry'])" (opened)="filterService()">
            <mat-option>
              <ngx-mat-select-search formControlName="polFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let port of filteredOriginPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('pol').setValue(''); agreementForm.get('polFilter').setValue('');" *ngIf="!!agreementForm.get('pol').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('pol').invalid">{{getErrorMessage('pol')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Destination Trade</mat-label>
          <mat-select name="destinationTrade" formControlName="destinationTrade" multiple [disabled]="isFieldDisabled('destinationCountry', 'pod')" (selectionChange)="setValidators('destinationTrade', ['destinationCountry', 'pod'])">
            <mat-option>
              <ngx-mat-select-search formControlName="destinationTradeFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let region of filteredRegions | async" [value]="region.REG_ID">{{region.REG_NAME}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('destinationTrade').setValue(''); agreementForm.get('destinationTradeFilter').setValue('');" *ngIf="!!agreementForm.get('destinationTrade').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('destinationTrade').invalid">{{getErrorMessage('destinationTrade')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Destination Country</mat-label>
          <mat-select name="destinationCountry" formControlName="destinationCountry" multiple [disabled]="isFieldDisabled('destinationTrade', 'pod')" (selectionChange)="setValidators('destinationCountry', ['destinationTrade', 'pod'])" (opened)="filterService()">
            <mat-option>
              <ngx-mat-select-search formControlName="destinationCountryFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let country of filteredDestinationCountries | async" [value]="country.COU_ID">{{country.COU_CODE}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('destinationCountry').setValue(''); agreementForm.get('destinationCountryFilter').setValue('');" *ngIf="!!agreementForm.get('destinationCountry').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('destinationCountry').invalid">{{getErrorMessage('destinationCountry')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>POD</mat-label>
          <mat-select name="pod" formControlName="pod" multiple [disabled]="isFieldDisabled('destinationTrade', 'destinationCountry')" (selectionChange)="setValidators('pod', ['destinationTrade', 'destinationCountry'])" (opened)="filterService()">
            <mat-option>
              <ngx-mat-select-search formControlName="podFilter" placeholderLabel="Search" noEntriesFoundLabel="No entries found"></ngx-mat-select-search>
            </mat-option>
            <mat-option *ngFor="let port of filteredDestinationPorts | async" [value]="port.DIM_SK_PORT">{{port.DHLPORTS_AIAOCD}}</mat-option>
          </mat-select>
          <button type="button" mat-icon-button matSuffix (click)="agreementForm.get('pod').setValue(''); agreementForm.get('podFilter').setValue('');" *ngIf="!!agreementForm.get('pod').value?.length">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <mat-error *ngIf="agreementForm.get('pod').invalid">{{getErrorMessage('pod')}}</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group spaced">
        <mat-form-field>
          <mat-label>Freetime</mat-label>
          <input matInput name="freetime" formControlName="freetime" (input)="getBeginDayValue('freetime', 'beginDay1', 'valuePerDay1'); clearPerDiem('freetime', 0); verifyPerdiem()">
          <mat-error *ngIf="agreementForm.get('freetime').invalid">{{getErrorMessage('freetime')}}</mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>Per-diem Type</mat-label>
          <mat-select name="perDiemType" formControlName="perDiemType">
            <mat-option value="0" (click)="this.agreementForm.get('freetime').setValue(''); clearPerDiem('freetime', 0);">CUSTOM RULE</mat-option>
            <mat-option *ngFor="let defaultRule of defaultRules" [value]="convertString(defaultRule.DIM_SK_CUS_RUL)" (click)="getPerdiem(defaultRule)">{{defaultRule.DIM_SK_CUS_RUL}} - {{defaultRule.DIM_RUL_DEF_NAM}} - {{defaultRule.DIM_DT_STR_EFF | date:'dd/MM/yyyy'}}</mat-option>
          </mat-select>
          <mat-error *ngIf="agreementForm.get('perDiemType').invalid">{{getErrorMessage('perDiemType')}}</mat-error>
        </mat-form-field>
      </div>
      <div *ngIf="agreementForm.get('freetime').value.trim() && !agreementForm.get('freetime').invalid">
        <div class="form-group-container">
          <h3>1st Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay1" formControlName="beginDay1" readonly>
              <mat-error *ngIf="agreementForm.get('beginDay1').invalid">{{getErrorMessage('beginDay1')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay1" formControlName="endDay1" (input)="handleEndDayInput('endDay1', 'beginDay2', 'valuePerDay2', 1)" (blur)="checkValidDate('endDay1', 'beginDay1')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('endDay1').invalid">{{getErrorMessage('endDay1')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay1" formControlName="valuePerDay1" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('valuePerDay1').invalid">{{getErrorMessage('valuePerDay1')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="agreementForm.get('endDay1').value.trim() && !agreementForm.get('endDay1').invalid">
          <h3>2nd Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay2" formControlName="beginDay2" readonly>
              <mat-error *ngIf="agreementForm.get('beginDay2').invalid">{{getErrorMessage('beginDay2')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay2" formControlName="endDay2" (input)="handleEndDayInput('endDay2', 'beginDay3', 'valuePerDay3', 2)" (blur)="checkValidDate('endDay2', 'beginDay2')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('endDay2').invalid">{{getErrorMessage('endDay2')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay2" formControlName="valuePerDay2" (blur)="checkValidValuePerDay('valuePerDay2', 'valuePerDay1')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('valuePerDay2').invalid">{{getErrorMessage('valuePerDay2')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="agreementForm.get('endDay2').value.trim() && !agreementForm.get('endDay2').invalid">
          <h3>3rd Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay3" formControlName="beginDay3" readonly>
              <mat-error *ngIf="agreementForm.get('beginDay3').invalid">{{getErrorMessage('beginDay3')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay3" formControlName="endDay3" (input)="handleEndDayInput('endDay3', 'beginDay4', 'valuePerDay4', 3)" (blur)="checkValidDate('endDay3', 'beginDay3')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('endDay3').invalid">{{getErrorMessage('endDay3')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay3" formControlName="valuePerDay3" (blur)="checkValidValuePerDay('valuePerDay3', 'valuePerDay2')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('valuePerDay3').invalid">{{getErrorMessage('valuePerDay3')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="form-group-container" *ngIf="agreementForm.get('endDay3').value.trim() && !agreementForm.get('endDay3').invalid">
          <h3>4th Period</h3>
          <div class="form-group">
            <mat-form-field>
              <mat-label>Begin Day</mat-label>
              <input matInput name="beginDay4" formControlName="beginDay4" readonly>
              <mat-error *ngIf="agreementForm.get('beginDay4').invalid">{{getErrorMessage('beginDay4')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>End Day</mat-label>
              <input matInput name="endDay4" formControlName="endDay4" (blur)="checkValidDate('endDay4', 'beginDay4')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('endDay4').invalid">{{getErrorMessage('endDay4')}}</mat-error>
            </mat-form-field>
            <mat-form-field>
              <mat-label>Value Per Day</mat-label>
              <input matInput name="valuePerDay4" formControlName="valuePerDay4" (blur)="checkValidValuePerDay('valuePerDay4', 'valuePerDay3')" [readonly]="agreementForm.get('perDiemType').value !== '0'">
              <mat-error *ngIf="agreementForm.get('valuePerDay4').invalid">{{getErrorMessage('valuePerDay4')}}</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="actions-group">
    <a mat-button color="accent" [routerLink]="['/agreements', agreementType]">Cancel</a>
    <a mat-stroked-button color="accent" [routerLink]="['/agreement', agreementType, 'clone', agreement.DIM_SK_CUS_RUL]" *ngIf="agreement && agreementAction !== 'clone'">Clone Agreement</a>
    <button mat-flat-button type="submit" color="accent" [disabled]="agreementForm.invalid">Save Agreement</button>
  </div>
</form>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.agreement">
  <mat-icon>menu_open</mat-icon>
</button>
