import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IProcessContainer } from './../models/process-container.model';
import { IInvoice, IInvoiceContainer } from 'src/app/models/invoice.model';
import { ICustomerException } from '../models/customer.model';

@Injectable({
  providedIn: 'root'
})
export abstract class InvoiceService {
  protected apiURL = environment.apiURL;

  constructor(protected httpClient: HttpClient) { }

  protected httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `${localStorage.getItem('token_type')} ${localStorage.getItem('access_token')}`
    })
  };

  getInvoices(filter: IInvoice): Observable<Array<IInvoice>> {
    return this.httpClient.get<Array<IInvoice>>(`${this.apiURL}/api/invoice?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getPartialInvoices(filter: IInvoice): Observable<Array<IInvoice>> {
    return this.httpClient.get<Array<IInvoice>>(`${this.apiURL}/api/invoice/partial?filter=${JSON.stringify(filter)}`, this.httpOptions);
  }

  getInvoice(invoice: IInvoice): Observable<IInvoice> {
    return this.httpClient.get<IInvoice>(`${this.apiURL}/api/invoice?filter=${JSON.stringify(invoice)}`, this.httpOptions);
  }

  getPartialInvoice(invoice: IInvoice): Observable<IInvoice> {
    return this.httpClient.get<IInvoice>(`${this.apiURL}/api/invoice/partial?filter=${JSON.stringify(invoice)}`, this.httpOptions);
  }

  getInvoiceContainer(invoice: IInvoice): Observable<Array<IInvoiceContainer>> {
    return this.httpClient.get<Array<IInvoiceContainer>>(`${this.apiURL}/api/invoice/container?filter=${JSON.stringify(invoice)}`, this.httpOptions);
  }

  exportContainers(invoice: IInvoice): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/invoice/export/containers`, invoice, this.httpOptions);
  }

  processInvoice(containers: Array<IProcessContainer>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/invoice/process`, containers, this.httpOptions);
  }

  processPartialInvoice(containers: Array<IProcessContainer>): Observable<Object> {
    return this.httpClient.post(`${this.apiURL}/api/invoice/partial/process`, containers, this.httpOptions);
  }

  putInvoice(invoice: Array<IInvoice>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/invoice`, invoice, this.httpOptions);
  }

  recalculateContainer(containers: Array<IProcessContainer>): Observable<Object> {
    return this.httpClient.put(`${this.apiURL}/api/processcontainer/recalculation`, containers, this.httpOptions);
  }

  getExceptions(invoice: IInvoice): Observable<Array<ICustomerException>> {
    return this.httpClient.get<Array<ICustomerException>>(`${this.apiURL}/api/invoice/exception?filter=${JSON.stringify(invoice)}`, this.httpOptions);
  }
}
