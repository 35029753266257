<h1 mat-dialog-title>Change Status</h1>
<div mat-dialog-content>
  <div class="event-info-container" *ngIf="isValidComment()">
    <div class="event-info">
      <div class="event-header">
        <p>{{data.element.PB_CMT_USER_NAME | titlecase}}</p>
        <span>•</span>
        <p>{{data.element.PB_CMT_INC_DT | date:'dd/MM/yyyy'}}</p>
      </div>
      <div class="event-status">
        <p>{{payableStatus[data.element.PB_CMT_ST]}}</p>
        <span>-</span>
        <p>{{payableSubStatus[data.element.PB_CMT_SUB_ST]}}</p>
      </div>
      <p class="action-name">{{data.element.PB_CMT}}</p>
    </div>
  </div>
  <form [formGroup]="form" (submit)="handleSubmit($event)">
    <div class="form-group">
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status">
          <mat-option value="0" *ngIf="validateOpenOptions()">New Process</mat-option>
          <mat-option value="1" *ngIf="validateOpenOptions()">Under Analysis</mat-option>
          <mat-option value="2" *ngIf="validateOpenOptions()">Dispute DHL</mat-option>
          <mat-option value="3" *ngIf="validateOpenOptions()">Dispute Carrier</mat-option>
          <mat-option value="4" *ngIf="validateOpenOptions()">Programed Payment</mat-option>
          <mat-option value="5" *ngIf="validateOpenOptions()">Paid (Dispute)</mat-option>
          <mat-option value="6" *ngIf="validateCloseOptions()">Paid</mat-option>
          <mat-option value="7" *ngIf="validateOpenOptions()">Invoice Canceled</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sub-Status</mat-label>
        <mat-select name="subStatus" formControlName="subStatus">
          <mat-option value="0" *ngIf="validateOpenOptions()">Under Internal Review</mat-option>
          <mat-option value="1" *ngIf="validateOpenOptions()">FT & PD Incorrect</mat-option>
          <mat-option value="2" *ngIf="validateOpenOptions()">Incorrect Invoice</mat-option>
          <mat-option value="3" *ngIf="validateOpenOptions()">Partial Invoice</mat-option>
          <mat-option value="4" *ngIf="validateOpenOptions()">Invoice Not Found</mat-option>
          <mat-option value="5" *ngIf="validateOpenOptions()">Waiting Confirmation (Free-time)</mat-option>
          <mat-option value="6" *ngIf="validateOpenOptions()">Waiting for Loss Approval</mat-option>
          <mat-option value="7" *ngIf="validateOpenOptions()">Waiting to Process Billing</mat-option>
          <mat-option value="8" *ngIf="validateOpenOptions()">Waiting Confirmation of Ship Transfer</mat-option>
          <mat-option value="9" *ngIf="validateOpenOptions()">Ok to Pay</mat-option>
          <mat-option value="10" *ngIf="validateCloseOptions()">Paid</mat-option>
          <mat-option value="11"*ngIf="validateOpenOptions()">Old Payment</mat-option>
          <mat-option value="12"*ngIf="validateOpenOptions()">Invoice Canceled</mat-option>
          <mat-option value="13"*ngIf="validateOpenOptions()">Ship Delay</mat-option>
          <mat-option value="14"*ngIf="validateOpenOptions()">Terminal Window Delay</mat-option>
          <mat-option value="15"*ngIf="validateOpenOptions()">Waiting Payment Order</mat-option>
          <mat-option value="16" *ngIf="validateCloseOptions()">Waiting Reimbursement</mat-option>
          <mat-option value="17" *ngIf="validateCloseOptions()">Dispute</mat-option>
          <mat-option value="18" *ngIf="validateOpenOptions()">Waiting Customer to Pay</mat-option>
        </mat-select>
      </mat-form-field>
    </div>    
    <div class="form-group vertical">
      <dhl-comment [showCommentsTab]="false" [offSideComments]="true" (comment)="onComment($event)"></dhl-comment>
      <dhl-attachment [showAttachmentsTab]="false" [offSideAttachments]="true" (upload)="onUpload($event)"></dhl-attachment>      
    </div>
    <div mat-dialog-actions>
      <button mat-stroked-button color="accent" type="button" (click)="close(null)">Cancel</button>
      <button mat-flat-button color="accent" type="submit" [disabled]="!comment">Apply</button>
    </div>
  </form>
</div>
