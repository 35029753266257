<header>
  <h2>Detention - Overview Analysis</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [matMenuTriggerFor]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu="matMenu" xPosition="before">
        <button mat-menu-item (click)="openOnHoldDialog({element: selection.selected})">
          <mat-icon>hourglass_empty</mat-icon>
          <span>On Hold</span>
        </button>
        <button mat-menu-item (click)="recalculateSale(selection.selected)">
          <mat-icon>refresh</mat-icon>
          <span>Recalculate Sale</span>
        </button>
        <button mat-menu-item (click)="recalculatePurchase(selection.selected)">
          <mat-icon>refresh</mat-icon>
          <span>Recalculate Purchase</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="import()">Import Excel</button>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="detentionTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #detentionTable [dataSource]="filteredDetentionTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" >ID</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_NUMBER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container">Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_NUMBER)" [matTooltip]="element.CTN_NUMBER" mat-cell *matCellDef="let element">{{element.CTN_NUMBER}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type">Type</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_CUSTOMER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_CUSTOMER)" [matTooltip]="element.CTN_CUSTOMER" mat-cell *matCellDef="let element">{{element.CTN_CUSTOMER}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_HBL)" [matTooltip]="element.CTN_HBL" mat-cell *matCellDef="let element">
          <a class="dhl-link" matTooltip="Open Shipment information" [routerLink]="['/shipment/edit', element.SHIP_ID]" target="_blank">
            {{element.CTN_HBL}}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_CAR)" [matTooltip]="element.CTN_CAR" mat-cell *matCellDef="let element">{{element.CTN_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_MBL)" [matTooltip]="element.CTN_MBL" mat-cell *matCellDef="let element">
          {{element.CTN_MBL}}
          <sup class="mbl-dup" *ngIf="element.CTN_DUP_MBL > 1">({{element.CTN_DUP_MBL}})</sup>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="START_DATE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Start Date">Start Date</th>
        <td mat-cell *matCellDef="let element">{{element.START_DATE | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_GOU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate Out">Gate Out</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.CTN_GOU">
            <button mat-stroked-button color="accent" (click)="openDateGateOutDialog({ mode: 'add', destiny: element.CTN_NUMBER, date: 'GOU', element })">Add Date</button>
          </ng-container>
          <ng-container *ngIf="element.CTN_GOU">
            <button mat-stroked-button [mat-menu-trigger-for]="menu" [disabled]="element.CTN_PROCESS_STATUS == '9' || element.CTN_IS_PARTIAL == '1'">{{element.CTN_GOU | date:'dd/MM/yyyy'}}</button>
            <mat-menu #menu="matMenu" overlapTrigger>
              <button mat-menu-item (click)="openDateGateOutDialog({ mode: 'edit', element: element.CTN_GOU, destiny: element.CTN_NUMBER, date: 'GOU', element })">
                <mat-icon color="primary">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="openDateGateOutDialog({ mode: 'delete', destiny: element.CTN_NUMBER, date: 'GOU', element })">
                <mat-icon color="primary">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_GIN_GOU">
        <th mat-header-cell *matHeaderCellDef>Days</th>
        <td mat-cell *matCellDef="let element">
          {{element.CTN_GIN_GOU}}
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_GIN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate In">Gate In</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.CTN_GIN">
            <button mat-stroked-button color="accent" (click)="openDateGateInDialog({ mode: 'add', destiny: element.CTN_NUMBER, date: 'GIN', element })">Add Date</button>
          </ng-container>
          <ng-container *ngIf="element.CTN_GIN">
            <button mat-stroked-button [mat-menu-trigger-for]="menu" [disabled]="element.CTN_PROCESS_STATUS == '9'">{{element.CTN_GIN | date:'dd/MM/yyyy'}}</button>
            <mat-menu #menu="matMenu" overlapTrigger>
              <button mat-menu-item (click)="openDateGateInDialog({ mode: 'edit', element: element.CTN_GIN, destiny: element.CTN_NUMBER, date: 'GIN', element })">
                <mat-icon color="primary">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="openDateGateInDialog({ mode: 'delete', destiny: element.CTN_NUMBER, date: 'GIN', element })">
                <mat-icon color="primary">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_LDG_GIN">
        <th mat-header-cell *matHeaderCellDef>Days</th>
        <td mat-cell *matCellDef="let element">
          {{element.CTN_LDG_GIN}}
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_LDG">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="!element.CTN_LDG">
            <button mat-stroked-button color="accent" (click)="openDateLoadingDialog({ mode: 'add', destiny: element.CTN_NUMBER, date: 'LDG', element })">Add Date</button>
          </ng-container>
          <ng-container *ngIf="element.CTN_LDG">
            <button mat-stroked-button [mat-menu-trigger-for]="menu" [disabled]="element.CTN_PROCESS_STATUS == '9'">{{element.CTN_LDG | date:'dd/MM/yyyy'}}</button>
            <mat-menu #menu="matMenu" overlapTrigger>
              <button mat-menu-item (click)="openDateLoadingDialog({ mode: 'edit', element: element.CTN_LDG, destiny: element.CTN_NUMBER, date: 'LDG', element })">
                <mat-icon color="primary">edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="openDateLoadingDialog({ mode: 'delete', destiny: element.CTN_NUMBER, date: 'LDG', element })">
                <mat-icon color="primary">delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>
          </ng-container>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_FRT">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Freetime">Freetime</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_FRT}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_RUL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Agreement">DDM Agreement</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_RUL}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_PROCESS_STATUS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Status">Process Status</th>
        <td mat-cell *matCellDef="let element">{{getStatusProcess(element.CTN_PROCESS_STATUS)}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_PF_STATUS">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Payable Status">Payable Status</th>
        <td mat-cell *matCellDef="let element">{{getStatusPayForecastCtn(element.CTN_PF_STATUS)}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_ONHOLD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by On Hold">On Hold</th>
        <td mat-cell *matCellDef="let element">
          <div class="onhold" (click)="openSidebar(element.SHIP_ID)">
            <span class="flag"
              [ngClass]="{
                'grey':  element.CTN_ONHOLD == 0,
                'green': element.CTN_ONHOLD == 1
              }">
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="PRTL_CTN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Has Partial">Has Partial</th>
        <td mat-cell *matCellDef="let element">
          <div class="onhold" (click)="openPrtlDialog({container: element})">
            <span class="flag"
              [ngClass]="{
                'grey':  element.CTN_IS_PARTIAL == 0,
                'green': element.CTN_IS_PARTIAL == 1
              }">
            </span>
          </div>
        </td>
      </ng-container>

      <ng-container matColumnDef="ADD_PARTIAL">
        <th mat-header-cell *matHeaderCellDef ></th>
        <td mat-cell *matCellDef="let element">
            <button mat-stroked-button matTooltip="Add Partial" color="warn" (click)="openNewPrtlDialog({title: 'Add', container: element, mode: 'add'})" [disabled]="element.CTN_PROCESS_STATUS != 2 || !element.CTN_GOU">
              Add Partial
            </button>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Recalculate Sale" color="primary" (click)="recalculateSale(element)" *ngIf="validateRecalculate(element,'sale')">
            <mat-icon>refresh</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Recalculate Purchase" color="primary" (click)="recalculatePurchase(element)" *ngIf="validateRecalculate(element,'purchase')">
            <mat-icon>refresh</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="detentionTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: detentionTableDisplayedColumns"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
