<form [formGroup]="payableForm" (submit)="onPayableFormSubmit($event)">
  <div class="title">
    <a mat-icon-button matTooltip="Return to Payables" aria-label="Return to Payables" [routerLink]="['/payables']">
      <mat-icon>chevron_left</mat-icon>
    </a>
    <h2>Outstanding</h2>
  </div>
  <div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Carrier</mat-label>
        <input matInput type="text" formControlName="carrier">
        <mat-error *ngIf="payableForm.get('carrier').invalid">{{getErrorMessage('carrier')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Invoice Number</mat-label>
        <input matInput type="text" formControlName="invoiceNumbers">
        <mat-error *ngIf="payableForm.get('invoiceNumbers').invalid">{{getErrorMessage('invoiceNumbers')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Emission Date</mat-label>
        <input matInput name="emissionDate" formControlName="emissionDate" [matDatepicker]="emissionDatePicker">
        <mat-datepicker-toggle matSuffix [for]="emissionDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #emissionDatePicker></mat-datepicker>
      </mat-form-field> 
      <mat-form-field>
        <mat-label>Expiration Date</mat-label>
        <input matInput name="expirationDate" formControlName="expirationDate" [matDatepicker]="expirationDatePicker" [min]="minExpirationDate">
        <mat-datepicker-toggle matSuffix [for]="expirationDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expirationDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>MBL</mat-label>
        <input matInput type="text" formControlName="mbl">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Booking</mat-label>
        <input matInput type="text" formControlName="booking">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Container</mat-label>
        <input matInput type="text" formControlName="container">
        <mat-error *ngIf="payableForm.get('container').invalid">{{getErrorMessage('container')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Equipament Type</mat-label>
        <input matInput type="text" formControlName="equipmentType">
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Contract</mat-label>
        <input matInput type="text" formControlName="contract">
      </mat-form-field>
      <mat-form-field>
        <mat-label>POL</mat-label>
        <input matInput type="text" formControlName="pol">
      </mat-form-field>
      <mat-form-field>
        <mat-label>POD</mat-label>
        <input matInput type="text" formControlName="pod">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Total Days</mat-label>
        <input matInput type="text" formControlName="totalDays">
        <mat-error *ngIf="payableForm.get('totalDays').invalid">{{getErrorMessage('totalDays')}}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Free Days</mat-label>
        <input matInput type="text" formControlName="freeDays">
        <mat-error *ngIf="payableForm.get('freeDays').invalid">{{getErrorMessage('freeDays')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Charge Days</mat-label>
        <input matInput type="text" formControlName="chargeDays">
        <mat-error *ngIf="payableForm.get('chargeDays').invalid">{{getErrorMessage('chargeDays')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Start Date</mat-label>
        <input matInput name="startDate" formControlName="startDate" [matDatepicker]="startDatePicker">
        <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #startDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field>
        <mat-label>End Date</mat-label>
        <input matInput name="endDate" formControlName="endDate" [matDatepicker]="endDatePicker" [min]="minEndDate">
        <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #endDatePicker></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="form-group">
      <mat-form-field>
        <mat-label>Total</mat-label>
        <input matInput type="text" formControlName="totalUsd">
        <mat-error *ngIf="payableForm.get('totalUsd').invalid">{{getErrorMessage('totalUsd')}}</mat-error>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Status</mat-label>
        <mat-select name="status" formControlName="status" disabled>
          <mat-option value="0">New Process</mat-option>
          <mat-option value="1">Under Analysis</mat-option>
          <mat-option value="2">Dispute DHL</mat-option>
          <mat-option value="3">Dispute Carrier</mat-option>
          <mat-option value="4">Programed Payment</mat-option>
          <mat-option value="5">Paid (Dispute)</mat-option>
          <mat-option value="6">Paid</mat-option>
          <mat-option value="7">Invoice Canceled</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Sub-Status</mat-label>
        <mat-select name="subStatus" formControlName="subStatus" disabled>
          <mat-option value="0">Under Internal Review</mat-option>
          <mat-option value="1">FT & PD Incorrect</mat-option>
          <mat-option value="2">Incorrect Invoice</mat-option>
          <mat-option value="3">Partial Invoice</mat-option>
          <mat-option value="4">Invoice Not Found</mat-option>
          <mat-option value="5">Waiting Confirmation (Free-time)</mat-option>
          <mat-option value="6">Waiting for Loss Approval</mat-option>
          <mat-option value="7">Waiting to Process Billing</mat-option>
          <mat-option value="8">Waiting Confirmation of Ship Transfer</mat-option>
          <mat-option value="9">Ok to Pay</mat-option>
          <mat-option value="10">Paid</mat-option>
          <mat-option value="11">Old Payment</mat-option>
          <mat-option value="12">Invoice Canceled</mat-option>
          <mat-option value="13">Ship Delay</mat-option>
          <mat-option value="14">Terminal Window Delay</mat-option>
          <mat-option value="15">Waiting Payment Order</mat-option>
          <mat-option value="16">Waiting Reimbursement</mat-option>
          <mat-option value="17">Dispute</mat-option>
          <mat-option value="18">Waiting Customer to Pay</mat-option>
        </mat-select>
      </mat-form-field>      
    </div>
  </div>
  
  <div class="timeline" *ngIf="payableAction === 'detail'">
    <h2>Status Comment Timeline</h2>
    <div>
      <div class="timeline-event" *ngFor="let event of timelineComments; let index = index">
        <div class="event-icon">
          <div class="vertical-line" [ngClass]="{'half-bottom': index === 0, 'half-top': index === timelineComments.length - 1}" *ngIf="timelineComments.length > 1"></div>
          <div class="icon">
            <mat-icon>check</mat-icon>        
          </div>
        </div>
        <div class="event-info-container">
          <div class="event-arrow"></div>
          <div class="event-info">
            <div class="event-header">
              <p>{{event.PB_CMT_USER_NAME}}</p>
              <span>•</span>
              <p>{{event.PB_CMT_INC_DT | date:'dd/MM/yyyy'}}</p>
            </div>
            <div class="event-status">
              <p>{{payableStatus[event.PB_CMT_ST]}}</p>
              <span>-</span>
              <p>{{payableSubStatus[event.PB_CMT_SUB_ST]}}</p>
            </div>
            <p class="action-name">{{event.PB_CMT}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="actions-group">
    <button mat-flat-button type="submit" color="accent" [disabled]="payableForm.invalid || isFormDisabled" *ngIf="this.payableAction !== 'detail'">Save Outstanding</button>
  </div>  
</form>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.payable && payableAction !== 'clone'">
  <mat-icon>menu_open</mat-icon>
</button>
