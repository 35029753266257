<header>
  <h2>Detention - Robots Date</h2>
  <div class="actions-container">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber != 0">{{filtersNumber}}</span>
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="menu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #menu xPosition="before">
        <button mat-menu-item (click)="approveAll()">
          <mat-icon>done_all</mat-icon>
          <span>Approve all</span>
        </button>
        <button mat-menu-item (click)="onReprocessContainer(selection.selected)">
          <mat-icon>restart_alt</mat-icon>
          <span>Reprocess containers</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export()" [disabled]="detentionTableDataSource.data.length === 0">Export</button>
    </div>
  </div>
</header>
<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #detentionTable [dataSource]="filteredDetentionTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="CTN_ID">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Id" >ID</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_ID}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_NUMBER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Container">Container</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_NUMBER)" [matTooltip]="element.CTN_NUMBER" mat-cell *matCellDef="let element">{{element.CTN_NUMBER}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_TYPE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Type">Type</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_TYPE}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_CUSTOMER">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Customer">Customer</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_CUSTOMER)" [matTooltip]="element.CTN_CUSTOMER" mat-cell *matCellDef="let element">{{element.CTN_CUSTOMER}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_HBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by HBL">House Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_HBL)" [matTooltip]="element.CTN_HBL" mat-cell *matCellDef="let element">
          <a class="dhl-link" matTooltip="Open Shipment information" [routerLink]="['/shipment/edit', element.SHIP_ID]" target="_blank">
            {{element.CTN_HBL}}
          </a>
        </td>
      </ng-container>
      <ng-container matColumnDef="SHIP_DPT_CODE">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
        <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_CAR">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Carrier">Carrier</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_CAR)" [matTooltip]="element.CTN_CAR" mat-cell *matCellDef="let element">{{element.CTN_CAR}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_MBL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by MBL">Master Bill</th>
        <td [matTooltipDisabled]="!isOverflow(element.CTN_MBL)" [matTooltip]="element.CTN_MBL" mat-cell *matCellDef="let element">{{element.CTN_MBL}}</td>
      </ng-container>      
      <ng-container matColumnDef="CTN_POL">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POL">Origin Port</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_POL}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_POD">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by POD">Destination Port</th>
        <td mat-cell *matCellDef="let element">{{element.CTN_POD}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_GOU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate Out">Gate Out</th>
        <td mat-cell *matCellDef="let element" class="highlight-text">{{element.CTN_GOU | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_GIN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Gate In">Gate In</th>
        <td mat-cell *matCellDef="let element" class="highlight-text">{{element.CTN_GIN | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="CTN_LDG">
        <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Loading">Loading</th>
        <td mat-cell *matCellDef="let element" class="highlight-text">{{element.CTN_LDG | date:'dd/MM/yyyy'}}</td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button matTooltip="Reject incoming date" (click)="onDateRejected(element)">
            <mat-icon color="primary">close</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Accept incoming date" (click)="onDateAccepted(element)" *ngIf="verifyDates(element)">
            <mat-icon color="primary">done</mat-icon>
          </button>
          <button mat-icon-button matTooltip="Reprocess container" (click)="onReprocessContainer(element)">
            <mat-icon color="primary">restart_alt</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="detentionTableDisplayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: detentionTableDisplayedColumns"></tr>
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
