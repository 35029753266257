<div class="timeline">
  <h2>Timeline</h2>
  <div>
    <div class="timeline-event" *ngFor="let event of timeline; let index = index">
      <div class="event-icon">
        <div class="vertical-line" [ngClass]="{'half-bottom': index === 0, 'half-top': index === timeline.length - 1}" *ngIf="timeline.length > 1"></div>
        <div class="icon">
          <mat-icon *ngIf="event.TIMELINE_FIELD !== 'Attachment' && event.TIMELINE_FIELD !== 'Comment'">{{timelineIcon(event)}}</mat-icon>
          <mat-icon *ngIf="event.TIMELINE_FIELD === 'Attachment' || event.TIMELINE_FIELD === 'Comment'">{{detailIcon(event)}}</mat-icon>          
        </div>
      </div>
      <div class="event-info-container">
        <div class="event-arrow"></div>
        <div class="event-info">
          <div class="event-header">
            <p>{{event.TIMELINE_USER}}</p>
            <span>•</span>
            <p>{{event.TIMELINE_DATETIME | date:'dd/MM/yyyy'}}</p>
          </div>
          <p class="action-name" *ngIf="event.TIMELINE_ACTION === 'create'">Created {{event.TIMELINE_FIELD || event.TIMELINE_GROUP}}</p>
          <p class="action-name" *ngIf="event.TIMELINE_ACTION !== 'create' && (event.TIMELINE_OLD_VALUE || event.TIMELINE_NEW_VALUE)" [matTooltip]="timelineAction(event)">{{timelineAction(event)}}</p>
          <p class="action-name" *ngIf="!event.TIMELINE_OLD_VALUE && !event.TIMELINE_NEW_VALUE">{{detailAction(event)}}</p>
        </div>
      </div>
    </div>
  </div>
</div>
