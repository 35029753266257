<header>
  <h2>Payables - Outstanding</h2>
  <div class="actions">
    <div class="search-container">
      <mat-form-field class="search">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix color="primary">search</mat-icon>
        <input matInput type="search" [formControl]="globalFilter">
        <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
          <mat-icon color="primary">close</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="actions-group">
      <button mat-stroked-button color="accent" (click)="openFilterDialog()">
        <span>Filters</span>
        <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>        
      </button>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="actionMenu" [disabled]="selection.isEmpty()">Action</button>
      <mat-menu #actionMenu xPosition="before">        
        <button mat-menu-item *ngIf="willChangeStatus(selection.selected)" (click)="openChangeStatusDialog({element: selection.selected})">
          <mat-icon>published_with_changes</mat-icon>
          <span>Change Status</span>
        </button>
        <button mat-menu-item *ngIf="willDelete(selection.selected)" (click)="openDeleteDialog({})">
          <mat-icon color="primary">delete</mat-icon>
          <span>Delete All</span>
        </button>
      </mat-menu>
      <button mat-flat-button color="accent" [mat-menu-trigger-for]="payableMenu">Add Outstanding</button>
      <mat-menu #payableMenu xPosition="before">
        <button mat-menu-item (click)="openImportDialog()">
          <mat-icon>download</mat-icon>
          <span>Import Excel</span>
        </button>
        <button mat-menu-item (click)="openImportPdfDialog()">
          <mat-icon>description</mat-icon>
          <span>Import PDF</span>
        </button>
        <a mat-menu-item [routerLink]="['/payable']">
          <mat-icon>add</mat-icon>
          <span>Create Outstanding</span>
        </a>
      </mat-menu>
      <button mat-flat-button color="accent" (click)="export()">Export</button>
    </div>
  </div>
</header>

<div *ngFor="let group of groups">
  <mat-slide-toggle
    [(ngModel)]="group.isEnabled" 
    (change)="toggleGroupVisibility()"
    color="primary">
    {{ group.name }}
  </mat-slide-toggle>
</div>

<div>
  <ng-container *ngIf="hideLoader; else loader">
    <table mat-table #table [dataSource]="filteredPayableTableDataSource" matSort [active]="sortCol" [direction]="sortOrder" (matSortChange)="onSortChange($event)">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container *ngIf="this.groups[0].isEnabled">
        <ng-container matColumnDef="PRC_SER">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_SER)" [matTooltip]="element.PRC_SER" mat-cell *matCellDef="let element">{{element.PRC_SER}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_HBL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>House Bill</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_HBL)" [matTooltip]="element.PRC_HBL" mat-cell *matCellDef="let element">{{element.PRC_HBL}}</td>
        </ng-container>
        <ng-container matColumnDef="SHIP_DPT_CODE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Dept">Dept</th>
          <td [matTooltipDisabled]="!isOverflow(element.SHIP_DPT_CODE)" [matTooltip]="element.SHIP_DPT_CODE" mat-cell *matCellDef="let element">{{element.SHIP_DPT_CODE}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_CUS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Customer</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_CUS)" [matTooltip]="element.PRC_CUS" mat-cell *matCellDef="let element">{{element.PRC_CUS}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_SHIP">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Shipment</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_SHIP)" [matTooltip]="element.PRC_SHIP" mat-cell *matCellDef="let element">{{element.PRC_SHIP}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_CTN">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Container</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_CTN)" [matTooltip]="element.PRC_CTN" mat-cell *matCellDef="let element">{{element.PRC_CTN}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_CTN_TYPE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_CTN_TYPE)" [matTooltip]="element.PRC_CTN_TYPE" mat-cell *matCellDef="let element">{{element.PRC_CTN_TYPE}}</td>
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="this.groups[1].isEnabled">
        <ng-container matColumnDef="PRC_INV_FRT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Freetime</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_INV_FRT)" [matTooltip]="element.PRC_INV_FRT" mat-cell *matCellDef="let element">{{element.PRC_INV_FRT}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_INV_CH_DAYS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Days</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_INV_CH_DAYS)" [matTooltip]="element.PRC_INV_CH_DAYS" mat-cell *matCellDef="let element">{{element.PRC_INV_CH_DAYS}}</td>
        </ng-container>      
        <ng-container matColumnDef="PRC_INV_VAL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Total</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_INV_VAL)" [matTooltip]="element.PRC_INV_VAL" mat-cell *matCellDef="let element">{{element.PRC_INV_VAL | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="PRC_INV_SITUATION">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Status</th>
          <td [matTooltipDisabled]="!isOverflow(element.PRC_INV_SITUATION)" [matTooltip]="element.PRC_INV_SITUATION" mat-cell *matCellDef="let element">{{invoiceStatusMap[element.PRC_INV_SITUATION]}}</td>
        </ng-container>
      </ng-container>
      
      <ng-container *ngIf="this.groups[2].isEnabled">
        <ng-container matColumnDef="PB_CAR">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Carrier</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_CAR)" [matTooltip]="element.PB_CAR" mat-cell *matCellDef="let element">{{element.PB_CAR}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_INV_NUM">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Invoice Number</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_INV_NUM)" [matTooltip]="element.PB_INV_NUM" mat-cell *matCellDef="let element">{{element.PB_INV_NUM}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_MBL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Master Bill</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_MBL)" [matTooltip]="element.PB_MBL" mat-cell *matCellDef="let element">{{element.PB_MBL}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_BK">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Booking</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_BK)" [matTooltip]="element.PB_BK" mat-cell *matCellDef="let element">{{element.PB_BK}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_CTN">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Container</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_CTN)" [matTooltip]="element.PB_CTN" mat-cell *matCellDef="let element">{{element.PB_CTN}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_CTN_TYPE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_CTN_TYPE)" [matTooltip]="element.PB_CTN_TYPE" mat-cell *matCellDef="let element">{{element.PB_CTN_TYPE}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_CONT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Contract</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_CONT)" [matTooltip]="element.PB_CONT" mat-cell *matCellDef="let element">{{element.PB_CONT}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_POL">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Origin Port</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_POL)" [matTooltip]="element.PB_POL" mat-cell *matCellDef="let element">{{element.PB_POL}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_POD">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination Port</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_POD)" [matTooltip]="element.PB_POD" mat-cell *matCellDef="let element">{{element.PB_POD}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_FRT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Freetime</th>
          <td mat-cell *matCellDef="let element">{{element.PB_FRT}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_CH_DAYS">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Days</th>
          <td mat-cell *matCellDef="let element">{{element.PB_CH_DAYS}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_ST_DT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
          <td mat-cell *matCellDef="let element">{{element.PB_ST_DT | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_END_DT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
          <td mat-cell *matCellDef="let element">{{element.PB_END_DT | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_VAL_USD">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount USD</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_VAL_USD)" [matTooltip]="element.PB_VAL_USD" mat-cell *matCellDef="let element">{{element.PB_VAL_USD | currency}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_PRC_ST">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td [matTooltipDisabled]="!isOverflow(payableStatus[element.PB_PRC_ST])" [matTooltip]="payableStatus[element.PB_PRC_ST]" mat-cell *matCellDef="let element">{{payableStatus[element.PB_PRC_ST]}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_PRC_SUB_ST">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Sub-Status</th>
          <td [matTooltipDisabled]="!isOverflow(payableSubStatus[element.PB_PRC_SUB_ST])" [matTooltip]="payableSubStatus[element.PB_PRC_SUB_ST]" mat-cell *matCellDef="let element">{{payableSubStatus[element.PB_PRC_SUB_ST]}}</td>
        </ng-container>
        <ng-container matColumnDef="PB_VER_ST">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Verification Status</th>
          <td mat-cell [matTooltip]="payableVerificationStatus[element.PB_VER_ST]" *matCellDef="let element">
            <span class="status-flag"
              [ngClass]="{
                'flag-grey': element.PB_VER_ST === 0,
                'flag-green': element.PB_VER_ST === 1,
                'flag-red': element.PB_VER_ST === 2,
                'flag-yellow': element.PB_VER_ST === 3
              }">
            </span>
          </td>
        </ng-container>
        <ng-container matColumnDef="PB_CMT_ATCH">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by Comment/Attachment">Comment / Attachment</th>
          <td [matTooltipDisabled]="!isOverflow(element.PB_CMT_ATCH)" [matTooltip]="element.PB_CMT_ATCH" mat-cell *matCellDef="let element">
            <a class="cmt-atch" (click)="openSidebar(element.PB_ID)">
              <span class="flag"
                [ngClass]="{
                  'grey': element.PB_CMT_ATCH === 0,
                  'green': element.PB_CMT_ATCH > 0             
                }">
              </span>
            </a>
          </td>
        </ng-container>
      </ng-container>
          
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button color="primary" matTooltip="Link File Info" (click)="linkFileInfo(element)">
            <mat-icon>link</mat-icon>
          </button>
          <a mat-icon-button matTooltip="Clone Payable" [routerLink]="['/payable','clone', element.PB_ID]">
            <mat-icon color="primary">content_copy</mat-icon>
          </a>
          <button mat-icon-button color="primary" matTooltip="Edit" [routerLink]="['/payable','edit', element.PB_ID]">
            <mat-icon>edit</mat-icon>
          </button>      
          <button mat-icon-button matTooltip="Change Status" (click)="openChangeStatusDialog({element})">
            <mat-icon color="primary">published_with_changes</mat-icon>
          </button>
          <a mat-icon-button color="primary" matTooltip="See details" [routerLink]="['/payable','detail', element.PB_ID]" target="_blank">
            <mat-icon>info</mat-icon>
          </a>
          <button *ngIf="willDelete(element)" mat-icon-button matTooltip="Delete" (click)="openDeleteDialog({element})">
            <mat-icon color="primary">delete</mat-icon>
          </button>
        </td>
      </ng-container>
    
      <!-- Header row select group -->
      <ng-container matColumnDef="header-select-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="1"></th>
      </ng-container>

      <!-- Header row first group -->
      <ng-container matColumnDef="header-first-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="7">File Information</th>
      </ng-container>
      
      <!-- Header row second group -->
      <ng-container matColumnDef="header-second-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="4">DHL Invoice</th>
      </ng-container>
    
      <!-- Header row fourth group -->
      <ng-container matColumnDef="header-third-group">
        <th mat-header-cell *matHeaderCellDef class="header-row" [attr.colspan]="18">Carrier Invoice</th>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="headerGroups"></tr>
      <tr mat-header-row *matHeaderRowDef="payableTableDisplayedColumns;"></tr>
      <tr mat-row *matRowDef="let row; columns: payableTableDisplayedColumns;"></tr>
      <!-- <tr mat-row *matRowDef="let row; columns: payableTableDisplayedColumns;" [routerLink]="'/payable/detail/' + row.PB_ID"></tr> -->
    </table>
    <mat-paginator [length]="length" [pageSizeOptions]="[5, 10, 15, 20, length]" [pageIndex]="pageNumber" [pageSize]="pageSize" (page)="onPaginateChange($event)" showFirstLastButtons aria-label></mat-paginator>
  </ng-container>  
  <ng-template #loader>
    <dhl-spinner></dhl-spinner>
  </ng-template>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>