<header>
    <h2>Analytics - FRP List</h2>
    <div class="actions">
      <div class="search-container">
        <mat-form-field class="search">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix color="primary">search</mat-icon>
          <input matInput type="search" [formControl]="globalFilter">
          <button type="button" mat-icon-button matSuffix (click)="globalFilter.setValue('')" *ngIf="globalFilter.value !== ''">
            <mat-icon color="primary">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="actions-group">
        <button mat-stroked-button color="accent" (click)="openFilterDialog()">
          <span>Filters</span>
          <span class="filter-badge" *ngIf="filtersNumber !== 0">{{filtersNumber}}</span>        
        </button>        
        <button mat-flat-button color="accent" [mat-menu-trigger-for]="parameterMenu">Parameters</button>
        <mat-menu #parameterMenu xPosition="before">        
          <button mat-menu-item (click)="openRateDialog()">
            <mat-icon>euro</mat-icon>
            <span>Rate</span>
          </button>
          <button mat-menu-item (click)="openBudgetDialog()">
            <mat-icon>attach_money</mat-icon>
            <span>Budget</span>
          </button>
          <button mat-menu-item (click)="openContainerDialog()">
            <mat-icon>warehouse</mat-icon>
            <span>Container Handling</span>
          </button>
        </mat-menu>
        <button mat-flat-button color="accent" [mat-menu-trigger-for]="financialMenu">Add Financial</button>
        <mat-menu #financialMenu xPosition="before">
          <button mat-menu-item (click)="openImportDialog()">
            <mat-icon>download</mat-icon>
            <span>Import Excel</span>
          </button>
        </mat-menu>
        <button mat-flat-button color="accent" (click)="export()">Export</button>
      </div>
    </div>
  </header>
  
  <div>
    <ng-container *ngIf="hideLoader; else loader">
      <table mat-table #table [dataSource]="financialTableDataSource" matSort #financialSort="matSort">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()"></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null" [checked]="selection.isSelected(row)"></mat-checkbox>
          </td>
        </ng-container>
      
        <ng-container matColumnDef="FBF_JOB_NUM">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Number</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_JOB_NUM)" [matTooltip]="element.FBF_JOB_NUM" mat-cell *matCellDef="let element">{{element.FBF_JOB_NUM}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_CHARGE_CODE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Charge Code</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_CHARGE_CODE)" [matTooltip]="element.FBF_CHARGE_CODE" mat-cell *matCellDef="let element">{{element.FBF_CHARGE_CODE}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_TRN_DEPT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Trn Dept</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_TRN_DEPT)" [matTooltip]="element.FBF_TRN_DEPT" mat-cell *matCellDef="let element">{{element.FBF_TRN_DEPT}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_LINE_TYPE">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Line Type</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_LINE_TYPE)" [matTooltip]="element.FBF_LINE_TYPE" mat-cell *matCellDef="let element">{{element.FBF_LINE_TYPE}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_LOCAL_AMOUNT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Local Amount</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_LOCAL_AMOUNT)" [matTooltip]="element.FBF_LOCAL_AMOUNT" mat-cell *matCellDef="let element">{{element.FBF_LOCAL_AMOUNT}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_TRN_ORG_NAME">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Trn Org Name</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_TRN_ORG_NAME)" [matTooltip]="element.FBF_TRN_ORG_NAME" mat-cell *matCellDef="let element">{{element.FBF_TRN_ORG_NAME}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_JOB_OPEN_DT">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Open Date</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_JOB_OPEN_DT)" [matTooltip]="element.FBF_JOB_OPEN_DT" mat-cell *matCellDef="let element">{{element.FBF_JOB_OPEN_DT | date:'dd/MM/yyyy'}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_HAWB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>HAWB</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_HAWB)" [matTooltip]="element.FBF_HAWB" mat-cell *matCellDef="let element">{{element.FBF_HAWB}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_MAWB">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>MAWB</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_MAWB)" [matTooltip]="element.FBF_MAWB" mat-cell *matCellDef="let element">{{element.FBF_MAWB}}</td>
        </ng-container>      
        <ng-container matColumnDef="FBF_POST_PERIOD">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Post Period (Month)</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_POST_PERIOD)" [matTooltip]="element.FBF_POST_PERIOD" mat-cell *matCellDef="let element">{{element.FBF_POST_PERIOD}}</td>
        </ng-container>
        <ng-container matColumnDef="FBF_POST_PERIOD_YEAR">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Post Period (Year)</th>
          <td [matTooltipDisabled]="!isOverflow(element.FBF_POST_PERIOD_YEAR)" [matTooltip]="element.FBF_POST_PERIOD_YEAR" mat-cell *matCellDef="let element">{{element.FBF_POST_PERIOD_YEAR}}</td>
        </ng-container>
              
        <tr mat-header-row *matHeaderRowDef="financialTableDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: financialTableDisplayedColumns;"></tr>
      </table>
      <mat-paginator
        #financialPaginator
        [pageSizeOptions]="[5, 10, 15, 20]"
        pageSize="20"
        showFirstLastButtons
      ></mat-paginator>
    </ng-container>  
    <ng-template #loader>
      <dhl-spinner></dhl-spinner>
    </ng-template>
  </div>  