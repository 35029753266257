<section id="print">
  <div class="watermark" *ngIf="invoice?.INVOICE_SITUATION === '4'">
    <h1 class="watermark-message">Cancelado</h1>
  </div>
  <div class="invoice-header">
    <div class="invoice-dhl">
      <img alt="teste" src="assets/img/dhl-logo.svg" style="width: 165px; padding-left: 10px;">
      <address>
        <p><strong>DHL GLOBAL FORWARDING (BRAZIL) LOGISTICS LTDA</strong></p>
        <p>CNPJ Matriz: 10.228.777/0001-61</p>
        <p>Santos - São Paulo</p>
      </address>
    </div>
    <div class="invoice-customer">
      <h3>Proforma N°. {{invoiceId}}</h3>
      <span class="date" *ngIf="invoice?.INVOICE_CUS_DT">
        <p><strong>Emissão Proforma: </strong>{{ invoice?.INVOICE_CUS_DT | date:'MMM d, y':'':'pt-BR' | titlecase}}</p>
      </span>
      <span>Cliente:</span>
      <address>
        <p><strong>{{invoice?.INVOICE_CUS_NAME}}</strong></p>
        <p>{{invoice?.INVOICE_CUS_ADDRESS | uppercase}}, {{invoice?.INVOICE_CUS_STATE}}</p>
      </address>
    </div>
  </div>
  <div class="invoice-content">
    <div class="invoice-title">
      <h3>{{invoice?.INVOICE_TYPE}}</h3>
      <h3 *ngIf="invoice?.INVOICE_PRTL !== 0">(PARCIAL {{invoice?.PRTL_CTN_ORDER?.replace('PARTIAL ','')}})</h3>
    </div>
    <div class="invoice-details">
      <div>
        <p><strong>Navio: </strong>{{invoice?.INVOICE_VESSEL}}</p>
        <p><strong>{{invoice?.INVOICE_TYPE == 'DEMURRAGE' ? 'Data Chegada: ' : 'Data Embarque: '}}</strong>{{invoice?.INVOICE_TYPE == 'DEMURRAGE' ? (invoice?.INVOICE_ATA | date:'dd/MM/yyyy') : (invoice?.INVOICE_ATD | date:'dd/MM/yyyy')}}</p>
        <p *ngIf="invoice?.INVOICE_CHARGE_TYPE"><strong>Tipo de Cobrança: </strong>{{invoice?.INVOICE_CHARGE_TYPE}}</p>
      </div>
      <div>
        <p><strong>Porto de Origem: </strong>{{invoice?.INVOICE_POL}}</p>
        <p><strong>Porto de Destino: </strong>{{invoice?.INVOICE_POD}}</p>
      </div>
      <div>
        <p><strong>House B/L: </strong>{{invoice?.INVOICE_HBL}}</p>
        <p *ngIf="invoice?.INVOICE_REFERENCE"><strong>Referência: </strong>{{invoice?.INVOICE_REFERENCE}}</p>
      </div>
    </div>
    <table>
      <thead>
        <tr>
          <th>Contêiner(es)</th>
          <th>Tipo(s)</th>
          <th>Início da Cobrança</th>
          <th>Fim da Cobrança</th>
          <th>Dias Livres</th>
          <th>Dias de Cobrança</th>
          <th>Tarifa Diária</th>
          <th>Total USD</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let container of invoiceContainers">
          <td>{{container?.INVOICE_CTN_NUMBER}}</td>
          <td>{{container?.INVOICE_CTN_TYPE}}</td>
          <td>{{container?.INVOICE_CTN_PENALTY_START | date:'dd/MM/yyyy'}}</td>
          <td>{{container?.INVOICE_CTN_PENALTY_END | date:'dd/MM/yyyy'}}</td>
          <td>{{container?.INVOICE_CTN_FREE_TIME}}</td>
          <td>{{container?.INVOICE_CTN_PENALTY_DAYS}}</td>
          <td>{{container?.INVOICE_CTN_RATE_RANGE}}</td>
          <td>{{container?.INVOICE_CTN_AMOUNT | currency:'USD ':'code'}}</td>
        </tr>
      </tbody>
    </table>
    <div class="invoice-total">
      <div>
        <strong>Total USD: </strong>
        <span>{{invoice?.INVOICE_TOTAL_AMOUNT | currency:'USD ':'code'}}</span>
      </div>
      <div *ngIf="(invoice?.INVOICE_TOTAL_AMOUNT - invoice?.INVOICE_AMT_DISCOUNT) > 0">
        <strong>Desconto: </strong>
        <span>{{invoice?.INVOICE_TOTAL_AMOUNT - invoice?.INVOICE_AMT_DISCOUNT | currency:'USD ':'code'}}</span>
      </div>
      <div *ngIf="(invoice?.INVOICE_TOTAL_AMOUNT - invoice?.INVOICE_AMT_DISCOUNT) > 0">
        <strong>Total: </strong>
        <span>{{invoice?.INVOICE_AMT_DISCOUNT | currency:'USD ':'code'}}</span>
      </div>
    </div>
  </div>
</section>
<div class="actions-container">
  <div class="action-group">
    <button mat-stroked-button color="accent" (click)="sendNotification()" *ngIf="invoice?.NOTIFICATION_SITUATION && invoice?.NOTIFICATION_SITUATION !== 'Closed'">
      <mat-icon>send</mat-icon>
      <span>Send Notification</span>
    </button>
  </div>
  <div class="action-group">
    <a mat-stroked-button color="accent" (click)="goBack()">Close</a>  
    <button mat-flat-button color="accent" [printTitle]="getNameProforma()" ngxPrint printSectionId="print" [useExistingCss]="true">
      <mat-icon>print</mat-icon>
      <span>Print</span>
    </button>
  </div>
</div>
<dhl-sidebar [isOpen]="isSidebarOpen" (close)="closeSidebar()">
  <dhl-comment [comments]="comments" (comment)="onComment($event)"></dhl-comment>
  <dhl-attachment [attachments]="attachments" (upload)="onUpload($event)"></dhl-attachment>
  <dhl-timeline [timeline]="timeline" *ngIf="timeline.length !== 0"></dhl-timeline>
</dhl-sidebar>
<button class="floating" mat-fab color="accent" matTooltip="Open sidebar" aria-label="Open details sidebar" (click)="openSidebar()" *ngIf="this.invoice">
  <mat-icon>menu_open</mat-icon>
</button>